import QuestionType from '@/entities/common/testing/QuestionType';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';

export default class TestOption {
  answerId: number;

  option?: string;
  image?: ImageSource;

  isCorrect: boolean;
  isChecked: boolean;
  isSuccess: boolean;
  isFailed: boolean;

  constructor(payload: any, questionType: QuestionType) {
    this.answerId = parseInt(payload.id, 10);
    this.isCorrect = parseInt(payload.correct, 10) === 1;
    this.isChecked = false;
    this.isSuccess = false;
    this.isFailed = false;

    if (questionType === QuestionType.SELECT_IMAGE) {
      this.image = createImageSmSource(payload.answer);
    } else {
      this.option = payload.answer;
    }

    if (questionType === QuestionType.SELECT_SEQUENCE) {
      this.image = createImageSmSource(payload.image);
      this.option = payload.answer;
    }

    if (questionType === QuestionType.ACCORDANCE) {
      this.image = createImageSmSource(payload.image);
    }
  }

  setStatuses(): void {
    this.isSuccess = this.isCorrect;
    this.isFailed = this.isChecked && !this.isCorrect;
  }
}
