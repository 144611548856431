import each from 'lodash/each';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import MaterialVideoStatistic from '@/entities/modules/learning-programs/MaterialVideoStatistic';
import VideoSource, { createVideoSource } from '@/entities/common/sources/VideoSource';
import InteractionStatistic from '@/entities/modules/learning-programs/video/InteractionStatistic';
import Question from '@/entities/common/testing/Question';
import QuestionType from '@/entities/common/testing/QuestionType';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import CustomRoute from '@/entities/common/CustomRoute';
import { sendEvent } from '@/providers/analyticsProvider';
import Vue from 'vue';

export default class MaterialVideo extends BaseMaterial {
  id: number;
  name: string;
  isRating: boolean;
  maxScore: number = 0;
  pointsForMcq: number;
  pointsForDnd: number;
  pointsForSequence: number;
  duration: number;
  ratingType: string;
  video?: VideoSource;
  image?: ImageSource;

  questions: Question[] = [];

  updatedAtTimestamp: number;

  statistic: MaterialVideoStatistic;

  constructor(statistic: IStatistic, payload: any) {
    super();

    this.statistic = statistic as MaterialVideoStatistic;

    this.id = parseInt(payload.id, 10);
    this.name = payload.name;

    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.maxScore = parseInt(payload.max_points, 10);
    this.ratingType = payload.rating_type;

    this.pointsForMcq = parseInt(payload.points_for_mcq, 10);
    this.pointsForDnd = parseInt(payload.points_for_dnd, 10);
    this.pointsForSequence = parseInt(payload.points_for_sequence, 10);
    this.duration = parseInt(payload.duration, 10);
    this.video = createVideoSource(payload.video);

    each(payload.interactions, o => this.questions.push(new Question(o)));
    this.questions = sortBy(this.questions, ['timestamp']);

    this.updatedAtTimestamp = payload.updated_at;
    this.image = createImageSmSource(payload.image);
  }

  /**
   * Информация для шапки видео
   */
  getInfo(): object[] {
    const parts = [];

    if (this.duration < 60) {
      parts.push([
        'common_d_from_d_seconds_text',
        this.duration,
        { 0: Math.floor(this.statistic.duration), 1: this.duration },
      ]);
    } else {
      const durationMinutes = Math.trunc(this.duration / 60);
      const durationStatMinutes = Math.trunc(
        (this.isMaterialCompleted() ? this.duration : this.statistic.duration) / 60,
      );
      parts.push([
        'common_d_from_d_minutes_text',
        durationMinutes,
        { 0: durationStatMinutes, 1: durationMinutes },
      ]);
    }

    if (this.maxScore > 0 && this.isRating) {
      parts.push([
        'learn_d_from_d_points_text',
        this.statistic.points,
        { 0: this.statistic.points, 1: this.maxScore },
      ]);
    }

    return parts;
  }

  /**
   * Тип материала
   */
  getMaterialType(): string {
    return 'video';
  }

  /**
   * Виджет материала для страницы просмотра программы обучения (выводится в секциях)
   * @param sectionMaterial
   */
  getToggleWidget(sectionMaterial: SectionMaterial): ToggleSectionMaterial {
    const status = this.statistic.getMaterialStatus();

    let widgetParts: unknown[] = [];

    if (status === MaterialStatus.ASSIGNED && this.maxScore !== 0) {
      widgetParts.push([
        'learn_earn_d_points_text',
        this.maxScore,
        { 0: this.maxScore.toLocaleString() },
      ]);
    } else {
      if (this.statistic.getPercent() > 0) {
        widgetParts.push(`${this.statistic.getPercent()}%`);
      }

      widgetParts = this.addPointsInfo(
        widgetParts,
        this.isRating,
        this.maxScore,
        this.statistic.points,
      );
    }

    return new ToggleSectionMaterial({
      id: this.id,
      type: this.getMaterialType(),
      routerTo: CustomRoute.TO_LEARNING_PROGRAMS_VIDEO,
      routerTrackTo: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_VIDEO,
      name: this.name,
      image: this.image,

      lokaliseTagLabel: 'common_video_text',
      buttonLabel: null,
      lokaliseButtonLabel:
        this.statistic.getPercent() < 95
          ? 'learn_course_watch_button'
          : 'learn_course_watch_again_button',
      isButtonOutlined: this.statistic.getPercent() >= 95,

      numberingTypeThrough: this.numberingTypeThrough,
      numberingTypeSections: this.numberingTypeSections,
      numberingTypeHierarchical: this.numberingTypeHierarchical,

      isRating: this.isRating,
      ratingType: this.ratingType,
      isRequired: sectionMaterial.isRequired,
      isLocked: sectionMaterial.isLocked,

      isPassed: status === MaterialStatus.PASSED,
      inProgress: status === MaterialStatus.IN_PROGRESS,

      parts: widgetParts,
    });
  }

  getStatistic(): MaterialVideoStatistic {
    return this.statistic;
  }

  setStatistic(statistic: MaterialVideoStatistic): void {
    this.statistic = statistic;
  }

  /**
   * Вернет объект для сохранения
   * @param saving
   */
  getSaving(saving: any): any {
    switch (saving.type) {
      case 'updateCurrentTime':
        return this.getSavingForCurrentTime(saving.ct);
      case 'saveInteraction':
        return this.getSavingSaveInteraction(
          saving.interactionId,
          saving.maxCurrentTime,
          saving.payload,
        );
      default:
        break;
    }

    // todo: log this
    return null;
  }

  /**
   * Объект для сохранения времени просмотра видео
   * @param ct
   */
  private getSavingForCurrentTime(ct: number): any {
    const currentTime = Math.trunc(ct);

    // если мы уже сохраняли это время, не будем делать это еще раз
    if (this.getStatistic().duration >= currentTime) {
      return null;
    }

    this.getStatistic().updateStartTimeIfWeNeeded();
    this.getStatistic().updateEndTime();

    return {
      id: this.id,
      type: this.getMaterialType(),
      start_time: this.getStatistic().startTime,
      end_time: this.getStatistic().endTime,
      duration: currentTime,
    };
  }

  /**
   * Объект для сохранения интерактивной вставки
   * @param interactionId
   * @param maxCurrentTime
   * @param payload
   */
  private getSavingSaveInteraction(
    interactionId: number,
    maxCurrentTime: number,
    payload: any,
  ): any {
    const interaction = this.getInteractionById(interactionId);

    const interactionStatistic = this.getInteractionStatisticById(interactionId);

    // вдруг чего-то не хватает
    if (!interaction || !interactionStatistic) {
      // todo: log this
      return;
    }

    // не будем сохранять вставку, если она была пройдена ранее
    if (interactionStatistic.getMaterialStatus() !== MaterialStatus.ASSIGNED) {
      return;
    }

    this.getStatistic().updateStartTimeIfWeNeeded();
    this.getStatistic().updateEndTime();

    let forSaving = null;

    switch (interaction.type) {
      case QuestionType.SELECT_SEQUENCE:
        forSaving = { id: interaction.id, user_answers: payload.map((item: any) => item.answerId) };
        sendEvent(Vue.prototype.$analytics, 'video_interaction_seq_used');
        break;

      case QuestionType.MCQ:
      case QuestionType.SELECT_IMAGE:
      case QuestionType.DND:
        forSaving = { id: interaction.id, user_answers: payload };
        break;

      case QuestionType.VIDEO:
        forSaving = { id: interaction.id, duration: payload };
        break;

      default:
        break;
    }

    if (!forSaving) {
      return;
    }

    return {
      id: this.id,
      type: this.getMaterialType(),
      start_time: this.getStatistic().startTime,
      end_time: this.getStatistic().endTime,
      duration: Math.trunc(maxCurrentTime),
      interactions: [forSaving],
    };
  }

  /**
   * Вернет интерактивную вставку по id
   */
  getInteractionById(id: number): Question | undefined {
    const question = find(this.questions, o => o.id === id);

    return question;
  }

  /**
   * Вернет статистику интерактивной вставки
   * @param id
   */
  getInteractionStatisticById(id: number): InteractionStatistic | undefined {
    return this.getStatistic().getInteractionStatisticById(id);
  }

  getTimeToContinueViewing(): number {
    let stopFinding = false;

    let autoPlayFrom = 0;

    each(this.questions, interactive => {
      const insert = this.getStatistic().getInteractionStatisticById(interactive.id);

      if (!insert) {
        return 0;
      }

      const isAppointedInsert = insert.status === 'appointed';

      const isFailedInsert = insert.status === 'fail';

      const isInsertBeforeDuration = interactive.timestamp < this.statistic.duration;

      if (!stopFinding) {
        if (insert.id === interactive.id) {
          if (isFailedInsert && interactive.isRequired && insert.points === 0) {
            stopFinding = true;
          } else if (
            (isInsertBeforeDuration && !isAppointedInsert) ||
            interactive.type === QuestionType.VIDEO
          ) {
            autoPlayFrom = interactive.timestamp + 1;
          } else if (isInsertBeforeDuration && isAppointedInsert) {
            stopFinding = true;
          }
        }
      }
    });

    if (!stopFinding) {
      autoPlayFrom = this.statistic.duration;
    }

    return autoPlayFrom;
  }

  isMaterialCompleted(): boolean {
    return this.statistic.duration >= (this.duration / 100) * 95; // поменять на проценты ?
  }
}
